@import '~bulma/sass/utilities/initial-variables.sass';
@import "~bulma/sass/utilities/functions.sass";

// ADD HERE variables you want to override

// Colour overrides
$primary: #5A2483;

$info: $cyan;
$success: $green;
$warning: $yellow;
$danger: $red;

$light: $white-ter;
$dark: $grey-darker;

$secondary: #8BC91D;
$secondary-invert: findColorInvert($secondary);

$tertiary: #FA6F65;
$tertiary-invert: findColorInvert($tertiary);

$transparent: transparent;
$transparent-invert: transparent;

// grey: hsl(0,0%,44%)
// darker: hsl(0,0%,29%)
$grey-darker:  hsl(0, 0%, 21%);
$grey-dark:    hsl(0, 0%, 29%);
$grey:         hsl(0, 0%, 44%);
$grey-light:   hsl(0, 0%, 71%);
$grey-lighter: hsl(0, 0%, 86%);

// Profile types colours
$business: #469944;
$business-invert: findColorInvert($business);
$charity: #FF6955;
$charity-invert: findColorInvert($charity);
$transporter: #E15AA4;
$transporter-invert: findColorInvert($transporter);
$volunteer: #9E5DAE;
$volunteer-invert: findColorInvert($volunteer);
$orange: orange;
$orange-invert: findColorInvert($volunteer);


$custom-colors: (
    "secondary":($secondary, $secondary-invert), "tertiary":($tertiary, $tertiary-invert), 
    // Profile types
    "business":($business, $business-invert),
    "charity":($charity, $charity-invert),
    "transporter":($transporter, $transporter-invert),
    "volunteer":($volunteer, $volunteer-invert),
    "orange": ($orange, $orange-invert),
    //
    "transparent": ($transparent, $transparent-invert)
);

// Responsiveness

// The container horizontal gap, which acts as the offset for breakpoints
$gap: 32px;
// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 769px;
// 960px container + 4rem
$desktop: 960px + (2 * $gap);
// 1152px container + 4rem;
$widescreen: 1152px + (2 * $gap);
$widescreen-enabled: true;
// 1344px container + 4rem
$fullhd: 1344px + (2 * $gap);
$fullhd-enabled: true;

// Miscellaneous
$radius-small: 0px;
$radius: 0px;
$radius-large: 0px;
$radius-rounded: 290486px;

$size-8: 0.875rem;
$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7 $size-8;

@import '~bulma/bulma.sass';
