.checkbox label input,
.radio label input {
  margin-right: 0.5rem;
}

.b-checkbox label input,
.b-radio label input {
  margin-right: 0.5rem;
}


.is-transparent {
    background-color: transparent !important;
}

.is-borderless {
    border-style: none !important;
}

.button.is-multiline {
  min-height: 2.25em;
  white-space: unset;
  height: auto;
  flex-direction: column;
}

.button.is-navigation {
    background-color: transparent;
    border: none !important;
    border-left: 4px solid transparent !important;    
    color: $grey;
    text-align: left;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    transition: background-color .15s linear;
    &:hover, &:active, &.active {
        border-left: 4px solid $primary !important;
        background-color: #ab7fcf;
        color: $white;    
    }

    .navigation_icon {
        display: inline-block;
        width: 41px;
        // height: unset;
        overflow: hidden;
    }

    span {
        flex: 1;
    }
}

// 
.navbar.is-primary a.navbar-item:hover {
    background-color: transparent !important;
}
