.admin-card {
    background-color: white;
    color: #4a4a4a;
    max-width: 100%;
    position: relative;
    box-shadow: 2px 2px 3px 2px rgba(0,0,0,0.2);
    padding: 10px 30px;
    margin-bottom: 36px;
    display: flex;
    justify-content: space-between;
}

.admin-card header {
    font-weight: 600;
    font-size: large;
    margin-bottom: 5px;
}

.admin-card-content {
    width: 80%;    
}