$loading-indicator-circle-radius: 8em;
$loading-indicator-active-border: #E6E8E8;
$loading-indicator-inactive-border: #828587;
$loading-indicator-border-width: 0.55em;

.loading_indicator {

    &__container {
        // woef
    }

    &__wrapper {
        display: block;
        position: relative;
        text-align: center;
        margin: 0 auto;
        width: $loading-indicator-circle-radius;
        height: $loading-indicator-circle-radius;    
    }

    &__indicator {
        display: block;
        border-radius: 50%;
        width: $loading-indicator-circle-radius;
        height: $loading-indicator-circle-radius;
        overflow: hidden;

        &:after {
          border-radius: 50%;
          width: $loading-indicator-circle-radius;
          height: $loading-indicator-circle-radius;
        }
        
        margin: 60px auto;
        font-size: 10px;
        position: relative;
        text-indent: -9999em;
        border-top: $loading-indicator-border-width solid $loading-indicator-active-border;
        border-right: $loading-indicator-border-width solid $loading-indicator-active-border;
        border-bottom: $loading-indicator-border-width solid $loading-indicator-active-border;
        border-left: $loading-indicator-border-width solid $loading-indicator-inactive-border;
        transform: translateZ(0);
        animation: load8 1.1s infinite linear;
        color: #828587 !important;
    }

    &__message {
        display: none;
    }

    &__icon {
        position: absolute;
        width: 100%;
        height: 100%;
        left: -15px;
        top: 15px;
        transform: rotate(20deg);
        color: #828587 !important;
        
        svg {
          height: 2.5em !important;
        }    
    }
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
