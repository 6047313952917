.profile__details h3 {
  font-family: BreeSerifRegular;
  font-weight: normal;
  margin-top: 0;
}

.profile__agreement {
  margin-top: 20px;
  max-width: 288px;
}

.profile__description {
  margin-bottom: 5px;
}

.profile__progress--container {
  margin: 10px;
}
.profile__progress--container p {
  margin-left: 0;
  margin-bottom: 10px;
}

.profile__progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e5e5e5;
  flex: 0.5;
  margin-left: 20px;
}
.profile__progress h3 {
  font-family: BreeSerifRegular;
  font-size: 18px;
  font-weight: normal;
  width: 100%;
  border-bottom: 1px solid #9a9a9a;
  align-self: flex-start;
}
.profile__progress p {
  font-size: 14px;
  color: #424242;
}

.profile_progress__container {
  position: relative;
  background: #bbaec4;
  width: 120px;
  height: 120px;
  border-radius: 60px;
  overflow: hidden;
  margin: 10px auto 15px;
}

.profile_progress__bar {
  display: block;
  background: #5a2e75;
  float: none;
  height: 100%;
  position: absolute;
  bottom: 0;
  width: 100%;
  -webkit-transition-property: height;
  transition-property: height;
}

.profile_progress__bar span {
  font-size: 36px;
  position: absolute;
  bottom: 35px;
  left: 0;
  color: white;
  text-align: center;
  width: 100%;
}

.profile--missing {
  background: #707070;
  color: #fff;
}
